import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CSectTitle03,
  CNewsList,
  CBtnList,
  CFacilityCard,
  CInlineDefinition,
  CBreadCrumb,
  LContact03,
} from '../../../../components/_index';
import infoChoice from '../../../../utils/info-choice';
import infoGet from '../../../../utils/info-get';
import '../../../../assets/_sass/page/shop.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'ショップ',
            sub: 'SHOP',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/facility/shop/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/facility/shop/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <section className="u_mtMedium">
        <LWrap>
          <CSectTitle03 title="お知らせ" />
          <CNewsList
            data={infoChoice(infoGet(), ['ショップ'], 5)}
            label={false}
          />
          <CBtnList
            data={[
              {
                label: 'お知らせ一覧',
                color: 'borderBlack',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="p_shop">
        <section className="u_pt120 u_pb120">
          <LWrap>
            <CSectTitle03 title="ショッピングアーケード" />
            <CFacilityCard
              data={[
                {
                  img: {
                    src: '/assets/images/about/facility/shop/img_shop.png',
                    alt: '',
                  },
                  floor: 'B2F',
                  title: (
                    <>
                      アビステホテルブティック
                      <br />
                      （ABISTE Hotel Boutique）
                    </>
                  ),
                  text: (
                    <>
                      <p className="u_minH01">
                        レディスウェア、バッグや服飾雑貨を中心に、ホテルブティックならではの多様なアイテムを取りそろえております。
                      </p>
                    </>
                  ),
                  definition: [
                    {
                      title: '営業時間',
                      text: <>10:00~20:00</>,
                    },
                    {
                      title: 'TEL',
                      text: <>03-3665-9574</>,
                    },
                  ],
                },
                {
                  img: {
                    src: '/assets/images/about/facility/shop/img_shop02.png',
                    alt: '',
                  },
                  floor: 'B2F',
                  title: 'アベール（Averre）',
                  text: (
                    <>
                      <p className="u_minH02">
                        レディースウェアを中心に、バッグ、ストールなどのファッションアイテムをトータルで取りそろえております。
                      </p>
                    </>
                  ),
                  definition: [
                    {
                      title: '営業時間',
                      text: <>10:30~19:30</>,
                    },
                    {
                      title: 'TEL',
                      text: <>03-5695-5561</>,
                    },
                  ],
                },
                {
                  img: {
                    src: '/assets/images/about/facility/shop/img_shop03.png',
                    alt: '',
                  },
                  floor: 'B2F',
                  title: 'エルベート（Herbette）',
                  text: (
                    <>
                      毛皮とインポートアパレルを中心に、海外の高級品やオリジナル商品など、ワンランク上のファッションにこだわる大人の女性に、最高品質の商品を自信を持ってご提供させていただいております。
                    </>
                  ),
                  definition: [
                    {
                      title: '営業時間',
                      text: <>10:00~20:00</>,
                    },
                    {
                      title: 'TEL',
                      text: <>03-5623-5255</>,
                    },
                  ],
                },
                {
                  img: {
                    src: '/assets/images/about/facility/shop/img_shop04.png',
                    alt: '',
                  },
                  floor: 'B2F',
                  title: (
                    <>
                      ヨシザワインク ハコザキ
                      <br />
                      <span>（HAIR＆GROOMING YOSHIZAWA Inc.HAKOZAKI）</span>
                    </>
                  ),
                  text: (
                    <>
                      ヘアカットだけでなく、ヘッドスパ、エステ、ネイルケアが受けられる大人の男性のためのグルーミングサロン。ゆったりとした空間でバーバーならではの魅力をご堪能ください。
                    </>
                  ),
                  definition: [
                    {
                      title: '営業時間',
                      text: (
                        <>
                          月~金曜日 　10:00~20:00
                          <br />
                          土・日・祝日　9:00~18:30
                        </>
                      ),
                    },
                    {
                      title: 'TEL',
                      text: <>03-3666-5028</>,
                    },
                  ],
                },
                {
                  img: {
                    src: '/assets/images/about/facility/shop/img_shop05.png',
                    alt: '',
                  },
                  floor: 'B2F',
                  title: 'フラワーショップガーデニア',
                  text: (
                    <>
                      <p className="u_minH05 u_minH06">
                        花の種類や色のご指定などお客様のご要望に応じ、お手配いたします。メッセージカードのご用意も可能です。
                      </p>
                    </>
                  ),
                  definition: [
                    {
                      title: '営業時間',
                      text: (
                        <>
                          10:00~18:00
                          <br />
                          定休日 火曜日・水曜日※祝日を除く
                        </>
                      ),
                    },
                    {
                      title: 'TEL',
                      text: <>03-3639-4187</>,
                    },
                  ],
                },
                {
                  img: {
                    src: '/assets/images/about/facility/shop/img_shop06.png',
                    alt: '',
                  },
                  floor: 'B1F',
                  title: (
                    <>
                      アートギャラリー
                      <br />
                      ル・モンド・デザール
                    </>
                  ),
                  text: (
                    <>
<p className="u_minH05 ">
                      国内外の巨匠から、これからのアート界を牽引する新進気鋭のアーティストに至るまで、お客様の生活を彩る上質な絵画作品をご提案してまいります。
                      </p>
                    </>
                  ),
                  definition: [
                    {
                      title: '営業時間',
                      text: (
                        <>
                          11:00~20:00
                          <br />
                          定休日 日曜日・月曜日
                        </>
                      ),
                    },
                    {
                      title: 'TEL',
                      text: <>090-2313-8201</>,
                    },
                  ],
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pb100">
          <LWrap>
            <CSectTitle03 title="その他" />
            <div className="p_otherWrap">
              <div className="p_otherWrap_inline">
                <p className="u_fs16 u_mb15">
                  4F 美容室　
                  <span className="u_fwb">ロイヤルパークホテル美容室</span>
                </p>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '営業時間',
                      text: (
                        <>
                          10:00~18:00
                          <br />
                          定休日 水曜日※祝日を除く
                        </>
                      ),
                    },
                    {
                      title: 'TEL',
                      text: '03-3639-3834',
                    },
                  ]}
                />
              </div>
              <div className="p_otherWrap_inline">
                <p className="u_fs16 u_mb15">
                  4F 衣裳室　
                  <span className="u_fwb">ブライダリウム ミュー</span>
                </p>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '営業時間',
                      text: (
                        <>
                          平日 10:00~18:00
                          <br className="u_sp" />
                          　土･日･祝日 10:00~19:00
                          <br />
                          定休日 水曜日※祝日を除く
                        </>
                      ),
                    },
                    {
                      title: 'TEL',
                      text: '03-3808-1122',
                    },
                  ]}
                />
              </div>
              <div className="p_otherWrap_inline">
                <p className="u_fs16 u_mb15">
                  4F 写真室　
                  <span className="u_fwb">リトル・マーサ</span>
                </p>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '営業時間',
                      text: (
                        <>
                          10:00~18:00
                          <br />
                          定休日 水曜日※祝日を除く
                        </>
                      ),
                    },
                    {
                      title: 'TEL',
                      text: '03-3667-3706',
                    },
                  ]}
                />
              </div>
              <div className="p_otherWrap_inline">
                <p className="u_fs16 u_fwb u_mb15">駐車場</p>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '収容台数',
                      text: '176台',
                    },
                  ]}
                />
              </div>
            </div>
            <ul className="c_noteList">
              <li>
                年末年始や特定期間におきましては、営業時間が変更となる場合がございます。
              </li>
            </ul>
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title="お問合せ"
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-3667-1111',
          subText: '（代表）',
          note: '受付時間 7:30～24:00',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
